import React from "react";

// All HOME PAGE ROUTES
import DocLanding from "../views/all-home-pages/DocLanding";
import CustomerSupport from "../views/all-home-pages/CustomerSupport";
import AppointmentScheduling from "../views/all-home-pages/AppointmentScheduling";

// Team inner pages
import Team from "../views/inner-pages/pages/team/Team";

// Faq inner pages
import Faq from "../views/inner-pages/pages/Faq";

// Pricing inner pages
import PricingCustomerSupport from "../views/inner-pages/pages/pricing/PricingCustomerSupport";

// FEATURES DROPDOWN ALL ROUTES
import Login from "../views/inner-pages/features/miscellaneous/Login";
import TermsConditions from "../views/inner-pages/features/miscellaneous/TermsConditions";
import ProductCustomerSupport from "../views/inner-pages/features/ProductCustomerSupport";

// DOCS DROPDOWN ALL ROUTES
import DocFullWidth from "../views/inner-pages/docs/DocFullWidth";
import Changelog from "../views/inner-pages/docs/Changelog";

// Not Found Page
import NotFound from "../views/NotFound";

// Route Specific
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollTopBehaviour from "../components/ScrollTopBehaviour";
import Dashboard from "../views/inner-pages/features/miscellaneous/Dashboard";
import DocumentsView from "../components/documents/Documents";


const Routes = () => {
  return (
    <>
      <Router>
        <ScrollTopBehaviour />
        <Switch>
          <Route exact path={`${process.env.PUBLIC_URL}/`} component={CustomerSupport}/>
          <Route exact path={`${process.env.PUBLIC_URL}/doc-landing`} component={DocLanding}/>
          <Route exact path={`${process.env.PUBLIC_URL}/appointment-scheduling`} component={AppointmentScheduling}/>
          <Route exact path={`${process.env.PUBLIC_URL}/faq`} component={Faq}/>
          <Route exact path={`${process.env.PUBLIC_URL}/pricing-cs`} component={PricingCustomerSupport}/>
          <Route exact path={`${process.env.PUBLIC_URL}/login`} component={Login}/>
          <Route exact path={`${process.env.PUBLIC_URL}/team`} component={Team}/>
          <Route exact path={`${process.env.PUBLIC_URL}/dashboard`} component={Dashboard}/>
          <Route exact path={`${process.env.PUBLIC_URL}/documents`} component={DocumentsView}/>
          <Route exact path={`${process.env.PUBLIC_URL}/termini-e-condizioni`} component={TermsConditions}/>
          <Route exact path={`${process.env.PUBLIC_URL}/product-customer-support`} component={ProductCustomerSupport}/>
          <Route exact path={`${process.env.PUBLIC_URL}/doc-full-width`} component={DocFullWidth}/>
          <Route exact path={`${process.env.PUBLIC_URL}/changelog`} component={Changelog}/>
          <Route path={`${process.env.PUBLIC_URL}/404`} component={NotFound}/>
          <Route component={NotFound}/>
        </Switch>
      </Router>
    </>
  );
};

export default Routes;
