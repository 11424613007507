import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
//import  Documents  from '../leads/Leads'
import  WaitingState  from '../wating-state/WaitingState'
import  ExpiredInstancesState  from '../expired-instances/ExpiredInstancesState'
import  AcceptedState  from '../accepted-state/AcceptedState'
import  ActiveInstancesState  from '../active-instances/ActiveInstancesState'
import Leads from "../leads/Leads";


const DocumentsView = () => {
  return (
    <div >
      <Tabs>
        <TabList className="nav nav-tabs pricing-nav-two pricing-nav-two-custom custom-dashboard">
          <Tab>Nuovo</Tab>
          <Tab>In attesa</Tab>
          <Tab>Accettato</Tab>
          <Tab>Istanze Attive</Tab>
          <Tab>Istanze Scadute</Tab>
        </TabList>
        <div className="tab-content-wrpper" style={{minHeight: '400px'}}>
          <TabPanel>
            <Leads/>
          </TabPanel>
          <TabPanel>
            <WaitingState/>
          </TabPanel>
          <TabPanel>
            <AcceptedState/>
          </TabPanel>
          <TabPanel>
            <ActiveInstancesState/>
          </TabPanel>
          <TabPanel>
          <ExpiredInstancesState/>
          </TabPanel>
        </div>
      </Tabs>
    </div>
  );
};

export default DocumentsView;