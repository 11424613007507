import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Routes from "./router/Routes";
import ScrollToTop from "./components/ScrollToTop";
import AOS from "aos";
import "aos/dist/aos.css";

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);
  window.addEventListener("load", AOS.refresh);

  return (
    <>
      <Helmet>
        <title>WeCo | La piattaforma completa per la formazione</title>
        <meta property="og:site_name" content="weco" />
        <meta
          property="og:url"
          content="https://we-co.it"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="WeCo: La piattaforma completa per la formazione"
        />
        <meta
          name="keywords"
          content="lms, formazione, business, ecm, agenas, crediti formativi, learning, gestionale, sdi, fatturazione, corsi, fad, aula, streaming, Gestionale formazione, corsi ecm, formazione agenas, saas"
        />
        <meta
          name="description"
          content="We.Co è la soluzione completa per l'organizzazione e la gestione completa della tua offerta formativa."
        />
        <meta name="description" content="Il gestionale per i professionisti della formazione" />
      </Helmet>
      {/* End Seo Helmt */}

      <ScrollToTop />
      <Routes />
    </>
  );
};

export default App;
