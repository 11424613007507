import React from "react";
import { Helmet } from "react-helmet";
import HeaderTwo from "../../../../components/header/HeaderTwo";
import FooterTwo from "../../../../components/footer/FooterTwo";
import CopyRightTwo from "../../../../components/footer/CopyRightTwo";
import ScrollspyNav from "react-scrollspy-nav";

const TermsConditions = () => {
  return (
    <div className="doc-container main-page-wrapper">
      <Helmet>
        <title>
          Termini e condizioni
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderTwo />
      {/* End Header */}

      {/* =====================================================
				Terms and Condition
			===================================================== */}

      <div className="terms_and_policy">
        <div className="container">
          <ScrollspyNav
            scrollTargetIds={["opt1", "opt2", "opt3", "opt4", "opt5", "opt6"]}
            activeNavClass="active"
            offset={170}
            scrollDuration="300"
          >
            <div className="row align-items-start">
            

              <div className="col-lg-12">
                {/* Tab panes */}
                <div className="tab-content ">
                  <div id="opt1">
                    <h2 className="font-gilroy-bold">Termini e condizioni</h2>
                    <div className="update-date">Data ultimo aggiornamento: 01/03/2023</div>
                    <h3>Definizioni</h3>
                    <p>
                    I. BeatData: identifica la società BeatData Srl con Sede legale in San Giorgio di Piano (BO) Via Cassino 6 e sede operativa in Cernusco sul Naviglio (MI) Via Camillo Benso Conte di Cavour 12, partita iva e codice fiscale 03590231209. II. Rivenditore: identifica la società contraente autorizzata da BeatData con apposito contratto alla rivendita dei servizi relativi al programma. Le generalità del Rivenditore sono riportate nella Sezione C.4 del modulo ULA. III. Cliente: identifica il soggetto unico intestatario della Licenza d’uso del Programma, utilizzatore del Programma e beneficiario dei servizi di cui al presente contratto. È il soggetto obbligato al pagamento dei corrispettivi economici. Le generalità del Cliente sono riportate nella Sezione A del presente contratto e nella Sezione A del modulo ULA. IV. Fornitore: identifica chi fornisce al Cliente i servizi di cui al presente contratto e coincide con BeatData. V. Programma: identifica il prodotto software di BeatData oggetto del presente contratto, fornito in licenza d’uso al Cliente, indicato nella Sezione C.1 del modulo ULA. Il Programma è di proprietà di BeatData. Il presente contratto è costituito da tre pagine e un modulo ULA.
                    </p>
                    <h3>1. Premessa</h3>
                    <p>
                    <b>1.1.</b> Le presenti “Condizioni Generali di Fornitura Software e Application Management Services” costituiscono parte integrante del Contratto (il “Contratto”), tra BeatData S.r.l. (d’ora in poi anche “Fornitore”) ed il Cliente (d’ora in poi Fornitore e Cliente congiuntamente le “Parti”); più precisamente (i) la lettera di Offerta Economica, (ii) la lettera di Offerta Tecnica e/o l’eventuale Analisi Dettagliata, sottoscritte dal Cliente per accettazione e (iii) le presenti Condizioni generali, costituiscono l’unico testo contrattuale che regolamenta i rapporti tra le Parti.
                    </p>
                    <p>
                    <b>1.2.</b> In caso di contrasto tra i già menzionati documenti, si osserverà il seguente ordine di prevalenza: (i) Offerta Economica, (ii) Analisi Dettagliata (iii) Offerta Tecnica e (iv) Condizioni Generali.
                    </p>
                    <p>
                    <b>1.3.</b> Qualsiasi modifica del Contratto non sarà valida se non redatta per iscritto e sottoscritta dai procuratori delle Parti.
                    </p>
                    <p>
                    <b>1.4.</b> Le presenti Condizioni Generali si applicano, anche in futuro, a tutte le forniture e/o manutenzione di software tra le Parti, salvo che non sia diversamente convenuto nelle successive offerte.
                    </p>
                    <h3>2. Oggetto</h3>
                    <p>
                    <b>2.1.</b> Oggetto del presente contratto è l’erogazione del servizio WeCo. Il servizio consente al Cliente, tramite una connessione Internet, di: accedere ad una suite di soluzioni per amministrare ed erogare corsi di formazione online e in aula. Il servizio è disponibile di norma 24 ore su 24 per tutti i giorni dell’anno, salvo eventuali interventi di manutenzione ordinaria e/o straordinaria che possono comportare la temporanea sospensione del servizio stesso. Il dettaglio delle caratteristiche e delle funzionalità del servizio sono disponibili on‐line all’indirizzo www.beatdata.it/weco e possono variare nel tempo senza necessità di preavviso.
                    </p>
                    <h3>3. Obblighi del Fornitore</h3>
                    <p>
                    <b>3.1.</b> Il Fornitore si obbliga a svolgere tutte le attività di cui all’Offerta nei tempi e nei modi ivi previsti, con la massima diligenza, organizzando e coordinando in modo ottimale i propri collaboratori.
                    </p>
                    <p>
                    <b>3.2.</b> Se il Contratto viene raccolto dal Rivenditore, questi deve inviarne copia a BeatData via strumenti elettronici e si impegna a far pervenire successivamente gli originali. In tal caso il Contratto dev’essere firmato dal Cliente e dal Rivenditore. BeatData non si assume alcuna responsabilità circa l’operato del Rivenditore. Il Cliente prende atto che BeatData subentrerà automaticamente al Rivenditore nel caso in cui a quest’ultimo venisse revocata l’autorizzazione a vendere il Programma e/o i servizi correlati. Il Rivenditore nulla potrà pretendere da BeaData a ragione del subentro.
                    </p>
                    <p>
                    <b>3.3.</b> Il fornitore si impegna a fornire al cliente assistenza, attività di supporto e aggiornamenti.
                    </p>
                    <h3>4. Obblighi del Fornitore in relazione al proprio personale - Sicurezza sul lavoro</h3>
                    <p>
                    <b>4.1.</b> Il Fornitore si impegna ad osservare, nei confronti dei propri incaricati e/o dipendenti, tutte le disposizioni legislative e regolamentari vigenti in materia di lavoro e di assicurazioni sociali nonché le norme vigenti di cui ai contratti collettivi di lavoro applicabili, a prescindere dalla sua adesione alle associazioni di categoria, riconoscendo ai suoi incaricati e/o dipendenti un trattamento conforme a dette norme. Il Fornitore garantisce di non utilizzare, direttamente o tramite terzi, manodopera di età inferiore a quella minima prevista dalla legge. Il Fornitore eserciterà, in via esclusiva ed in completa autonomia, i poteri organizzativi, direttivi e disciplinari nei confronti del personale dipendente utilizzato per la corretta esecuzione delle attività relative al presente Contratto.
                    </p>
                    <p>
                    <b>4.2.</b> Il Fornitore garantisce l‘osservanza delle norme di sicurezza e antinfortunistiche e, nel caso di attività svolte presso le sedi del Cliente ovvero presso altri locali da quest’ultimo indicati, s‘impegna a compiere tutte le azioni necessarie e/o opportune affinché il proprio personale si attenga alle disposizioni ricevute dal Cliente nonché alle leggi in materia di sicurezza sul lavoro e ciò anche con riguardo alle attrezzature, ai materiali ed agli impianti che avrà in uso, adottando all’uopo idonee misure preventive.
                    </p>
                    <p>
                    <b>4.3.</b> In conformità a quanto previsto dall’art. 26, comma 8, D.Lgs. n. 81/2008, il Fornitore si impegna a munire il proprio personale occupato nell’esecuzione dei Servizi di apposita tessera di riconoscimento corredata di fotografia, contenente le generalità del lavoratore e l’indicazione del datore di lavoro.
                    </p>
                    <h3>5. Responsabilità del fornitore</h3>
                    <p>
                    <b>5.1.</b> Il Fornitore presta garanzia per vizi, difetti o difformità della prestazione solo ed esclusivamente nei casi inderogabili di legge, garantendo esclusivamente le capacità professionali dei soggetti impiegati. È altresì esclusa qualsiasi responsabilità del Fornitore per eventuali danni derivati al Cliente da vizi sopravvenuti o imputabili: (i) al mancato aggiornamento del Programma secondo le versioni fornite dal Fornitore o all’installazione di aggiornamenti non elaborati direttamente dal Fornitore; (ii) al mancato o non corretto funzionamento dell’elaboratore e del suo sistema operativo; (iii) all’uso improprio o al mancato uso del Programma da parte del Cliente.
                    </p>
                    <p>
                    <b>5.2.</b> Fatto salvo il caso di dolo o colpa grave, nonché i casi previsti da disposizioni inderogabili di legge, la responsabilità del Fornitore sarà limitata ad un importo pari a quanto pagato dal Cliente in ragione dei rapporti regolati dal Contratto nel corso dei 12 (dodici) mesi antecedenti il verificarsi dell’evento che ha generato il danno. Resta inteso che il Fornitore risponderà unicamente dei danni immediati e diretti, con l’esclusione di qualsiasi responsabilità per danni indiretti (inclusi, a titolo esemplificativo, danni per perdite o mancato guadagno, per interruzione di attività, per perdita di dati e/o informazioni).
                    </p>
                    <p>
                    <b>5.3.</b> Il Fornitore non è responsabile di eventuali malfunzionamenti di programmi di terzi, sebbene installati o integrati ai propri programmi ai fini della fornitura del Programma.
                    </p>
                    <h3>6. Obblighi del Cliente</h3>
                    <p>
                    <b>6.1.</b> Il Cliente è tenuto a prestare la propria massima collaborazione col Fornitore per la regolare esecuzione delle attività previste nel Contratto.
                    </p>
                    <p>
                    <b>6.2.</b> Il Cliente è tenuto a pagare puntualmente i corrispettivi dovuti al Fornitore secondo quanto previsto nel Contratto e in particolare al successivo articolo 8.
                    </p>
                    <p>
                    <b>6.3.</b> Il Cliente si asterrà dal manomettere, modificare, convertire, decodificare, de-compilare o disassemblare il Programma ed inoltre dal manomettere, modificare o rimuovere il logo ed i marchi o qualsiasi altro particolare attestante la proprietà del Programma.
                    </p>
                    <p>
                    <b>6.4.</b> Il Cliente è consapevole che l’eventuale conoscenza da parte di terzi dei Codici di Accesso consentirebbe a questi ultimi l’utilizzo del servizio a nome del Cliente. Il Cliente è pertanto tenuto a conservare i Codici di Accesso con la massima riservatezza e sarà responsabile di ogni danno causato dalla conoscenza dei suddetti Codici di Accesso da parte di terzi. I Codici di Accesso possono essere modificati dal Cliente in qualunque momento tramite il pannello di configurazione di WeCo e, per ragioni di sicurezza, si consiglia di provvedere periodicamente a cambiarli e a verificare che non sia recuperabile tramite informazioni che possano essere note a terzi. I dati eventualmente memorizzati dal Cliente vengono criptati e conservati in apposite server farm, e sono protetti tramite password decisa dal Cliente e non conosciuta dal Fornitore (pertanto qualora il Cliente dovesse perderla i dati salvati saranno inutilizzabili).
                    </p>
                    <p>
                    <b>6.5.</b> Il Cliente è interamente responsabile dei dati contenuti in WeCo e delle possibili controversie inerenti alla diffusione online (internet) dei dati stessi. Il Cliente solleva il Fornitore da responsabilità di carattere civile e/o penale come conseguenza dell’immissione e diffusione dei dati, dal Cliente introdotti, che infrangono qualunque norma vigente e di prossima emanazione, durante la validità del presente contratto.
                    </p>
                    <h3>7. Condizioni di utilizzo</h3>
                    <p>
                    <b>7.1.</b> L’erogazione del servizio avviene esclusivamente attraverso una connessione Internet e il servizio è fruibile esclusivamente su dispositivi elettronici (computer, tablet, ecc.).
                    </p>
                    <p>
                    <b>7.2.</b> Il servizio sarà erogato fino al raggiungimento delle soglie in termini di storage e durata massima dei corsi VOD come definito dal piano di configurazione dell’offerta; superata tali soglie, e previa formale comunicazione, al Cliente potrà essere fatturata e addebitata una maggiorazione di pagamento annuale, relativa ai dati in eccedenza. Nel caso in cui il Cliente intenda estendere le soglie definite, è sua facoltà chieder al Fornitore l’ampliamento del servizio, alle condizioni indicate nel listino prezzi in vigore al momento della richiesta. In tal caso, al Cliente sarà fatturato e addebitato separatamente il corrispettivo relativo all’ampliamento.
                    </p>
                    <p>
                    <b>7.3.</b> Il Cliente non acquisirà mai la proprietà dei servizi; WeCo è di proprietà unica ed esclusiva di del Fornitore; è espressamente vietato al Cliente qualsiasi utilizzo a scopo di lucro e/o qualunque comportamento fraudolento. È altresì espressamente vietato fare copie in tutto e/o in parte del contenuto di WeCo, manomettere e/o modificare in qualsiasi modo i contenuti, manomettere e/o rimuovere e/o modificare Copyright, marchi o qualsiasi altro particolare attestante la proprietà di WeCo, o in nessun altro modo ridurre il contenuto di WeCo in una forma comprensibile all’uomo.
                    Il Fornitore si riserva tutti i diritti non esplicitamente conferiti dal presente contratto, inclusi i diritti di copyright nazionali e internazionali. Il Cliente conviene di attuare ogni cosa possibile al fine di evitare con qualunque mezzo qualsiasi riproduzione, pubblicazione o distribuzione non autorizzata del contenuto di WeCo. Trasferimenti del contenuto di WeCo ad altra persona o società, non autorizzati per iscritto dal Fornitore, costituiscono violazione del copyright e come tali sono crimini perseguibili dalla legge. Il Fornitore si riserva la facoltà di interrompere la fruizione del servizio e di adire le vie legali qualora riscontrasse violazioni del presente contratto.
                    </p>
                    <p>
                    Aggiornamenti.
                    </p>
                    <p>
                    <b>7.4.</b> Il Cliente è consapevole e accetta che il Fornitore non può essere ritenuto responsabile per eventuali problematiche riferite alle proprie configurazioni di sistema e/o alla mancanza di un abbonamento internet attivo e/o alla mancanza, in tutto o in parte, dei requisiti tecnici indicati nella documentazione predisposta, disponibile separatamente online. Il Fornitore si riserva la facoltà di variare nel tempo i requisiti e le specifiche tecniche, anche senza preavviso.
                    </p>
                    <h3>8. Corrispettivi</h3>
                    <p>
                    <b>8.1.</b> Il corrispettivo e le modalità di pagamento e fatturazione sono indicati nell’Offerta Economica che riporterà anche l'eventuale scontistica applicabile. 
                    </p>
                    <p>
                    <b>8.2.</b> In caso di mancato pagamento anche di uno solo dei corrispettivi pattuiti, il Fornitore potrà a suo insindacabile giudizio (1) revocare la licenza d’uso del Programma e/o l’accesso ai servizi e, fermo ogni altro rimedio di legge, risolvere di diritto il Contratto ai sensi dell’ art. 1456 del codice civile, oppure
                    </p>
                    <p>
                    (2) sospendere l'esecuzione delle sue prestazioni contrattuali, fermo anche in tal caso ogni altro rimedio di legge; il Fornitore potrà pertanto optare per i rimedi di cui al punto (1) anche dopo avere optato per la sospensione prevista al punto (2). Il Cliente è quindi consapevole che il mancato pagamento anche di un solo canone periodico potrà comportare fra le altre cose il blocco temporaneo o definitivo dell’utilizzo del Programma, di tutte le sue funzioni e di tutti i servizi ad esso collegati. In tal caso la sospensione dell’erogazione dei servizi non potrà mai costituire inadempimento contrattuale da parte del Fornitore.
                    </p>
                    <p>
                    <b>8.3.</b> Nel caso di ritardato pagamento, saranno dovuti dal momento della scadenza del termine di pagamento gli interessi moratori ed il rimborso delle spese per il recupero credito previsti dal D.Lgs n. 231 del 9.10.2002 e s.m.i., fatto salvo l’eventuale maggior danno subito, senza necessità di messa in mora.
                    </p>
                    <p>
                    <b>8.4.</b> I corrispettivi saranno annualmente aggiornati, automaticamente e senza necessità di richiesta in qualsiasi forma da parte del Fornitore, nella misura del 100% della variazione assoluta dell’indice ISTAT (indice dei prezzi al consumo per le famiglie di operai ed impiegati), relativo ai dodici mesi precedenti, senza che questo possa dar luogo a recesso dal contratto.
                    </p>
                    <p>
                    <b>8.5.</b> I pagamenti saranno sempre dovuti, indipendentemente da eventuali eccezioni o contestazioni.
                    </p>
                    <p>
                    <b>8.6.</b> Le prestazioni oggetto di questo contratto di servizi vengono erogate a partire dal giorno successivo a quello di ricevimento da parte del Cliente della comunicazione, tramite posta elettronica, di avvenuta attivazione del servizio stesso e comunque successivamente alla ricezione, da parte del Fornitore, del presente contratto debitamente compilato e sottoscritto dalle parti interessate unitamente al pagamento dell’eventuale canone. Il Fornitore, pertanto, accetta di partecipare al presente contratto che ha predisposto e sarà obbligata, al pari delle altre parti contraenti nei suoi confronti, nei termini ed alle condizioni quivi tutte pattuite salvo che, entro quindici giorni dal ricevimento del contratto, manifesti la propria non accettazione.
                    </p>
                    <p>
                    <b>8.7.</b> I canoni per il servizio WeCo sono indicati al punto C del presente contratto, salvo adeguamenti annuali come definito nel punto 8.5. Il canone è annuale, anticipato e fatturato annualmente. Il Cliente accetta espressamente fin d’ora le predette condizioni di fatturazione.
                    </p>
                    <p>
                    La fattura, emessa annualmente o mensilmente, contiene l’importo del canone annuale (eventualmente suddiviso in rate mensili) e in aggiunta una commissione periodica di rimborso spese di emissione sdd; il pagamento della/e fattura/a avverrà tramite addebito diretto in conto corrente. Il Fornitore si riserva il diritto di modificare e variare le condizioni del servizio e le condizioni dell’offerta o di concedere una diversa forma di pagamento, in qualsiasi momento e a proprio insindacabile giudizio, gli eventuali nuovi listini verranno comunicati dal Fornitore al Cliente almeno 30 giorni prima della seguente scadenza di pagamento mensile. Il Cliente nei 30 giorni successivi potrà recedere per iscritto qualora l’aumento sia superiore al 25%. Si intende valido qualsiasi strumento a disposizione del Fornitore per le comunicazioni inerenti le variazioni di listino, a titolo di esempio non esaustivo: online tramite il sito www.beatdata.it/weco, tramite posta elettronica. Il contratto, la fatturazione e il relativo pagamento sono soggetti all’art. 1186 Codice Civile.
                    </p>
                    <h3>9. Divieto di Cessione</h3>
                    <p>
                    <b>9.1.</b> Il Contratto non è cedibile né la Licenza può essere ceduta, data in locazione, prestata o trasferita a terzi in alcuna forma senza il consenso scritto del Fornitore; eventuali estensioni della licenza d’uso dei Programmi a soggetti terzi saranno regolate da appositi e separati contratti.
                    </p>
                    <h3>10. Durata</h3>
                    <p>
                    <b>10.1.</b> Il presente contratto avrà durata a partire dalla data di attivazione del servizio e fino al termine di dodici mesi.
                    </p>
                    <p>
                    <b>10.2.</b> Il contratto si rinnoverà tacitamente al termine del periodo originariamente pattuito, per successivi periodi di 12 (dodici) mesi, alle condizioni vigenti al momento del rinnovo, salvo disdetta di una delle parti, da comunicarsi a mezzo PEC o tramite lettera raccomandata con ricevuta di ritorno entro 60 (sessanta) giorni prima della scadenza. In caso di disdetta presentata oltre il termine consentito, tale disdetta si intenderà inviata per il periodo contrattuale successivo e non avrà effetto sul periodo in essere; pertanto, il Cliente dovrà corrispondere per intero anche il corrispettivo per il periodo successivo. In qualsiasi caso di disdetta da parte del Cliente, il Fornitore sospenderà il servizio.
                    </p>
                    <p>
                    <b>10.3.</b> In mancanza della disdetta scritta di cui al punto 10.2, il Cliente autorizza sin da ora ed irrevocabilmente il Fornitore, per i periodi successivi alla scadenza contrattuale, ad emettere fatture degli importi relative agli anni successivi per i servizi di cui al punto C.
                    </p>
                    <h3>11. Proprietà intellettuale</h3>
                    <p>
                    <b>11.1.</b> La proprietà intellettuale del Software, della documentazione di corredo, delle idee e delle tecniche che vengano sviluppate dal Fornitore, anche in collaborazione con il Cliente, in esecuzione del Contratto, appartiene in via esclusiva al Fornitore, atteso che al Cliente viene attribuito unicamente il diritto d'utilizzo in conformità con i termini indicati nel Contratto e dalla legge.
                    </p>
                    <p>
                    <b>11.2.</b> È facoltà del Fornitore riprodurre alle pagine web eventualmente componenti il Software il proprio marchio e/o link di collegamento al proprio sito web o indirizzi di posta elettronica, con finalità distintiva e “a firma” del Software e/o dei suoi componenti.
                    </p>
                    <h3>12. Clausola risolutiva espressa</h3>
                    <p>
                    <b>12.1.</b> Ai sensi e per gli effetti di cui all’art. 1456 c.c., in caso di mancato rispetto delle norme contenute nei punti 6.2 (pagamento dei corrispettivi), 6.3 (interventi non autorizzati sul Software), 9 (non cedibilità), il Fornitore avrà la facoltà di dichiarare la risoluzione di diritto del Contratto e di trattenere a titolo di penale quanto ricevuto dal Cliente a titolo di acconto o anticipo, ancorché per prestazioni non ancora eseguite o comunque dal Cliente non ancora usufruite, e di richiedere il pagamento integrale di quanto pattuito per lo sviluppo del Programma e/o dei canoni a scadere per l’intera durata del Contratto in relazione alla componente Servizi. Resta salvo in ogni caso il diritto del Fornitore di agire per il risarcimento del danno ulteriore.
                    </p>
                    <h3>13. Recesso e Clausola penale</h3>
                    <p>
                    <b>13.1.</b> In caso di recesso unilaterale dal Contratto da parte del Cliente, il Fornitore avrà la facoltà di trattenere a titolo di penale quanto ricevuto a titolo di acconto o anticipo, ancorché per prestazioni non ancora eseguite o comunque dal Cliente non ancora usufruite e di richiedere il pagamento integrale di quanto pattuito per lo sviluppo del Programma e/o dei canoni a scadere per l’intera durata del Contratto in relazione alla componente Servizi. Resta salvo in ogni caso il diritto del Fornitore di agire per il risarcimento del danno ulteriore.
                    </p>
                    <p>
                    <b>13.2.</b> Il Fornitore potrà recedere dal Contratto con effetto immediato, previa comunicazione per iscritto, nel caso in cui: (i) intervenga un cambiamento sostanziale nell’assetto e/o nelle modalità di controllo dell’impresa del Cliente; (ii) venga posta in liquidazione o cessi l’attività d’impresa del Cliente; (iii) venga presentata a carico del Cliente una domanda di sottoposizione ad una qualsiasi procedura concorsuale.
                    </p>
                    <h3>14. Forza Maggiore</h3>
                    <p>
                    <b>14.1.</b> Nessuna delle Parti sarà responsabile verso l'altra per danni, perdite, o impossibilità di prestazione/adempimenti causati da calamità naturali, stato di guerra o qualsiasi altra causa totalmente imprevedibile e al di fuori del proprio controllo. L’esonero di responsabilità di cui sopra è subordinato alla comunicazione scritta all’altra arte dell’evento di Forza Maggiore. Tale comunicazione deve essere effettuata dalla parte che subisce l’evento all’altra Parte entro il termine di 2 (due) giorni da quando l’evento si verifica o da quando sia possibile effettuare la comunicazione. Qualora l’evento di Forza Maggiore dovesse protrarsi per un periodo di oltre 7 (sette) giorni le Parti concorderanno ogni eventuale modifica che si rendesse necessaria al Contratto.
                    </p>
                    <h3>15. Confidenzialità</h3>
                    <p>
                    <b>15.1.</b> Le Parti si impegnano a mantenere confidenziale, a non comunicare e/o divulgare a terzi ed a non usare, salvo che al fine di adempiere agli obblighi di cui al Contratto e nei limiti previamente concordati per iscritto tra le stesse, qualsivoglia informazione commerciale, amministrativa, di marketing, finanziaria e/o di altra natura relativa all’altra parte, di cui vengano comunque a conoscenza durante il Contratto e/o in relazione al medesimo, salvo che per le informazioni di pubblico dominio. Le parti, inoltre, si impegnano a mantenere confidenziale e a non comunicare e/o divulgare a terzi i termini e le condizioni del Contratto, ad eccezione di quelle informazioni strettamente necessarie per adempiere al Contratto e/o richieste dalle autorità ai sensi di norme inderogabili. Le parti faranno quanto necessario, prendendo le necessarie iniziative anche presso il personale di cui possano, direttamente e/o indirettamente, avvalersi, per garantire in modo efficace e completo la natura confidenziale delle già menzionate informazioni. Il presente articolo rimarrà valido ed efficace anche dopo lo scioglimento del Contratto per qualsivoglia motivo, per un periodo di 1 anno.
                    </p>
                    <h3>16. Foro di competenza esclusiva</h3>
                    <p>
                    <b>16.1.</b> Per qualunque controversia che dovesse sorgere in relazione al Contratto sarà esclusivamente competente il Foro di Bologna.
                    </p>
                    <h3>17. Informativa sul trattamento dei dati personali</h3>
                    <p>
                    <b>17.1.</b> I dati personali forniti dal Cliente potranno essere trattati unicamente per l’esecuzione del Contratto (art. 6.1.b. Regolamento), compresa la gestione della anagrafica cliente e l’invio di comunicazioni di servizio in relazione all’esecuzione della prestazione richiesta, e per adempiere agli obblighi di legge a cui è soggetto il titolare del trattamento (art. 6.1.c Regolamento).
                    I dati potranno essere trattati solo da: soggetti autorizzati (art.29 Regolamento, ad esempio personale amministrativo e collaboratori); o nominati responsabili del trattamento (art. 28 Regolamento) o titolari autonomi del trattamento cui i dati possono essere comunicati per motivi di interesse pubblico o in adempimento ad obblighi di legge (ad esempio consulenti del titolare del trattamento, società che offrono servizi informatici e di supporto dell’attività aziendale, istituti di credito, autorità pubbliche). I dati del Cliente potranno altresì essere comunicati alla società controllante. I nominativi e gli indirizzi di tali soggetti sono disponibili su richiesta dell’interessato. In merito all’esercizio dei diritti dell’interessato e agli altri diritti previsti dall’art. 13 del Regolamento (UE) 2016/679 (General Data Protection Regulation)
                    </p>
                    <p>
                    <b>17.2.</b> I Qualora il Servizio preveda operazioni di trattamento di dati (personali o particolari) per conto del Cliente, del quale lo stesso sia Titolare ai sensi del Regolamento (UE) 2016/679, BeatData S.r.l. si impegna ad accettare la nomina a Responsabile di tale trattamento ai sensi dell’art. 28 del Regolamento medesimo, con atto separato, allegato al presente contratto, che le Parti si riservano di formalizzare entro la data di decorrenza del servizio. In caso di cessione del Contratto, di subappalto o di subfornitura autorizzati, BeatData S.r.l. s’impegna a far accettare al cessionario/subappaltatore/subfornitore l’eventuale nomina a Responsabile del trattamento. In tale sua attività di trattamento dei dati, BeatData S.r.l. si impegna a rispettare le norme del Regolamento (UE) 2016/679, del D. Lgs. 196/2003 Codice in materia di protezione dei dati personali, recante disposizioni per l'adeguamento dell'ordinamento nazionale al Regolamento (UE) 2016/679 nonché, a rispettare le eventuali istruzioni impartite dal Cliente.
                    </p>
                    <h3>18. Comprensione e accettazione</h3>
                    <p>
                    Il Cliente riconosce di aver letto il presente contratto, di capirlo ed accetta di assoggettarsi alle sue regole, ed inoltre riconosce che esso è l’espressione completa ed esclusiva dell’accordo tra le parti.
                    </p>
                    <h3>19. Responsabilità ai sensi del D.Lgs. n. 231/2001</h3>
                    <p>
                    Il Cliente prende atto che BeatData Srl, onde prevenire la commissione di reati ai sensi del D.Lgs 231/2001, ha adottato un Modello di Organizzazione, Gestione e Controllo e ha nominato il proprio Organismo di Vigilanza. Il Cliente, pertanto, assicura e garantisce che, nell'esecuzione del Contratto e, in generale, nello svolgimento della propria attività, agirà nel rispetto delle disposizioni di cui al D.Lgs. 231/2001. La violazione delle suddette prescrizioni potrà costituire causa di risoluzione, da parte di BeatData S.r.l., del Contratto e di qualunque altro accordo esistente tra le parti, ai sensi e per gli effetti dell’articolo 1456 c.c., fatta salva la facoltà di BeatData S.r.l. di richiedere il risarcimento degli eventuali danni.
                    </p>
                    
                    
                    
                  
                  </div>
                </div>
                {/*  /.tab-content */}
              </div>
            </div>
          </ScrollspyNav>
        </div>
      </div>

      <footer className="theme-footer-two pt-150 md-pt-80">
        <div className="top-footer">
          <div className="container">
            <FooterTwo />
          </div>
          {/* /.container */}
        </div>
        {/* /.top-footer */}

        <div className="container">
          <div className="bottom-footer-content">
            <CopyRightTwo />
          </div>
          {/*  /.bottom-footer */}
        </div>
      </footer>
      {/* /.theme-footer-one */}
    </div>
  );
};

export default TermsConditions;
