import axios from 'axios'
import ServicesConfig from './ServicesConfig';

class AuthServices {
    login(username, password) {
        return axios
            .post(
                ServicesConfig.baseUrl + 'reseller/login',
                { username:username , password:password }
            )
            .then((response) => {
                return response.data
            })
    }

    logout() {
        localStorage.removeItem('user')
    }
}

export default new AuthServices()
