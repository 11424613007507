import { /*useEffect, useMemo, useRef,*/ useState } from 'react'
/*
import { useHistory } from 'react-router-dom'
import { debounce } from 'lodash'
import Table from '../table/Table'
import Modal from '../modal/Modal';
import LeadServices from '../../services/LeadServices';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";*/

const FormField = ({
    label,
    name,
    required,
    register,
    errors,
    value
}) => {
    const [fieldValue /*, setFieldValue*/] = useState(null);

    /*useEffect(() => {
        setFieldValue(value);
    }, []);

    const handleChange = event => {
        setFieldValue(event.target.value);
      };*/

    return (
        <div>
            <label>{label} {required === 'true' && ('*')}</label>
            <input type="text" name="{name}" className={` ${errors[name] ? "form-control is-invalid" : "form-control"}`}  {...register(name)} value={ fieldValue /*onChange={handleChange}*/} />
            {errors[name] && ( <div className="invalid-feedback">{errors[name]?.message}</div> )}
        </div>
    )
}


export default FormField
