import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'

const Modal = ({
    title,
    message,
    open,
    confirmLabelButton,
    cancelLabelButton,
    confirmLoading = false,
    translation,
    onClose,
    onConfirm,
    children
}) => {
    if (!open) {
        return <></>
    }

    return (
        <Transition appear show={open} as={Fragment} >
            <Dialog onClose={onClose} className="modal-area fixed overflow-y-auto z-50 inset-0">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>
                <Dialog.Panel className="modal-body">
                    <div className='modal-header'>
                        <Dialog.Title>{title}
                            <Dialog.Description>
                                {message}
                            </Dialog.Description>
                        </Dialog.Title>
                    </div>
                    {children}
                </Dialog.Panel>
            </Dialog>
        </Transition>
    )
}

export default Modal
