import React from "react";

const CopyRightTwo = () => {
  return (
    <div className="bottom-footer-content">
      <div className="d-flex align-items-center justify-content-center">
      <p>
        Copyright @{new Date().getFullYear()}{" "}
        <a
          href="https://we-co.it"
          target="_blank"
          rel="noopener noreferrer"
        >
          BeatData
        </a>{" "}
        S.r.l.
      </p>
      </div>
    </div>
    //   /.bottom-footer
  );
};

export default CopyRightTwo;
