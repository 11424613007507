import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const FaqContent = [
  {
    title: " Facile ed intuitivo",
    desc: ` Un'unica piattaforma semplice, completa e personalizzabile per gestire tutti i processi che ruotano attorno alla formazione.
    Cattura l'attenzione, aumenta il coinvolgimento e migliora l'efficienza dell'apprendimento`,
    expand: "a",
  },
  {
    title: "La piattorma completa, sicura e affidabile",
    desc: ` We.Co. è lo strumento web ideale per la tua formazione online o in aula adatto a Enti di formazione pubblici e privati, Aziende e società che intendano avvalersi di una piattaforma all-inclusive, semplice, veloce e sicura per l’organizzazione di corsi di formazione residenziale, a distanza, sincrona e asincrona.`,
    expand: "b",
  },
  {
    title: " Formazione e amministrazione",
    desc: ` Gestisci i corsi e la fatturazione verso i clienti. Esporta i documenti di vendita in formato XML pronti per l'invio di Fatture Elettroniche.
    Controlla i pagamenti e crea piani di rateizzazione personalizzabili per ogni iscritto.`,
    expand: "c",
  },
];

const Faq = () => {
  return (
    <div className="accordion-style-two pr-5">
      <div className="faq-wrraper">
        <Accordion preExpanded={["a"]} allowZeroExpanded>
          {FaqContent.map((item, i) => (
            <AccordionItem className="card" key={i} uuid={item.expand}>
              <AccordionItemHeading className="card-header">
                <AccordionItemButton>
                  <h5 className="mb-0">
                    <button className="btn btn-link">{item.title}</button>{" "}
                  </h5>
                </AccordionItemButton>
              </AccordionItemHeading>
              {/* Accordion Heading */}
              <AccordionItemPanel className="card-body fadeInUp">
                <p>{item.desc}</p>
              </AccordionItemPanel>
              {/* Accordion Body Content */}
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default Faq;
