/*import { useEffect, useMemo, useRef, useState } from 'react'
import Table from '../table/Table'
import Modal from '../modal/Modal';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import FormField from '../form-field/FormField';
//import Pagination from '../../components/pagination/Pagination'
import LeadServices from '../../services/LeadServices';
*/

const ActiveInstancesState = () => {
    /*
    const usersListRef = useRef();
    const [modalOpened, setModalOpened] = useState(false);
    const [modalMode, setModalMode] = useState(0);
    const [setModalDeleteOpened] = useState(false);
    const [formData, setFormData] = useState();
  
    const [usersList,setUsersList]=useState([]);
    const [setTotal]=useState([]);
    const [setTotalPage]=useState([]);
    var isLoading = false;
    usersListRef.current = usersList;


    const onSearchHandler = (event) => {
        setUserManagementState({
            ...userManagementState,
            page: 1,
            search: event.target.value.trim(),
        })
    }

    const debouncedOnSearchChange = useMemo(
        () => debounce(onSearchHandler, 3000),
        [onSearchHandler]
    )

    const validationSchema = Yup.object().shape({
        companyName: Yup.string().required("Il campo Ragione sociale e richiesto")
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;

    function saveLeadData(data, e) {

        switch (modalMode) {
            case 0:
                LeadServices.create(data).then((response) => {
                    if(response.message === 'success'){
                      setModalOpened(false);
                      e.preventDefault();
                      e.target.reset();
                    }
                }).catch((err) => { console.log(err); });
            break;
            case 1:
                LeadServices.save(data).then((response) => {
                    if(response.message === 'success'){
                      setModalOpened(false);
                      e.preventDefault();
                      e.target.reset();
                    }
                }).catch((err) => { console.log(err); });
            break;
            case 2:
                LeadServices.convert(data).then((response) => {
                    if(response.message === 'success'){
                      setModalOpened(false);
                      e.preventDefault();
                      e.target.reset();
                    }
                }).catch((err) => { console.log(err); });
            break;
        
            default:
            break;
        }
    }

   function getLeadData(id) {
        LeadServices.get(id).then((response) => {
            if(response.message == 'success'){
                setFormData(response.data);
                setModalMode(1);
                setModalOpened(true);
            }
        }) .catch((err) => { console.log(err); });
    }

    function downloadPdf(id) {
        LeadServices.getPdf(id).then((response) => {
            if(response.message == 'success'){
                setFormData(response.data);
                setModalMode(2);
                setModalOpened(true);
            }
        }) .catch((err) => { console.log(err); });
    }

    function confirmContract(id) {
        LeadServices.confirmContract(id).then((response) => {
            if(response.message == 'success'){
                setFormData(response.data);
                setModalMode(2);
                setModalOpened(true);
            }
        }) .catch((err) => { console.log(err); });
    }

    const fetch = async() => {
        LeadServices.list().then((response) => {
            if(response.message == 'success'){
                for (let index = 0; index < response.data.usersList.length; index++) {
                    const element = response.data.usersList[index];
                    response.data.usersList[index].contact = element.mobile + ', ' + element.email;
                }

                setUsersList(response.data.usersList);
                setTotal(response.data.total);
                setTotalPage(response.data.totalPage);
                usersListRef.current = usersList;
            } 
        }).catch((err) => { console.log(err); });   
    }

    useEffect(() => {
        fetch();
    }, []);

    const columns = useMemo(
        () => [
            {
                Header: 'Ragione Sociale',
                accessor: 'company',
            },
            {
                Header: 'Referente',
                accessor: 'refferece',
            },
            {
                Header: 'Indirizzo',
                accessor: 'address',
            },
            {
                Header: 'Contatti',
                accessor: 'contact',
            },
            {
                Header: 'Data Creazione',
                accessor: 'creationDate',
            },
            {
                Header: 'Data Modifica',
                accessor: 'lastEditDate',
            },
            {
                Header: 'Azioni',
                accessor: 'actions',
                Cell: (props) => {
                    const leadRow = props.row.original;
                    return (
                        <div><button onClick={ setModalDeleteOpened(true) }><i className='fa fa-database' title='Gestisciti istanza'></i></button>
                        </div>
                    )
                },
            },
        ],
    []
    )

    return (
        <div className={'w-full'}>
            {isLoading === false && !!usersList && (
                <div>
                    <Table columns={ columns } data={ usersList } addCallback={() => {  }} title={'Istanze attive'} description={'Tutte le istanze attive'} addBtn={false} />
                    <Modal
                        translation={ null }
                        title= 'In attesa'
                        message='Tutti i dati del tuo contatto'
                        open={ modalOpened }
                        onClose={() => { }}
                        onConfirm={() => { }}>
                        <div>
                            <form onSubmit={ handleSubmit(saveLeadData) }>
                                <div className='row'>
                                    <input type="hidden" name="id" value=""/>
                                    <div className='col-lg-4'>
                                        <FormField label="Ragione sociale" value={(formData != null ? formData.companyName : '')} name="companyName" required="true" register={register} errors={errors}/>
                                    
                                      
                                    
                                    </div>
                                    <div className='col-lg-4'>
                                        <FormField label="Cognome" name="lastName" value={(formData != null ? formData.lastName : '')} required="false" register={register} errors={errors}/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <FormField label="Nome" name="firstName" value={(formData != null ? formData.lastName : '')} required="false" register={register} errors={errors}/>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <FormField label="Indirizzo" name="address" value={(formData != null ? formData.address : '')} required="false" register={register} errors={errors}/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <FormField label="Citta" name="city" value={(formData != null ? formData.city : '')} required="false" register={register} errors={errors}/>
                                    </div>
                                    <div className='col-lg-2'>
                                        <FormField label="CAP" name="zip" value={(formData != null ? formData.zip : '')} required="false" register={register} errors={errors}/>
                                    </div>
                                    <div className='col-lg-2'>
                                        <FormField label="Provincia" value={(formData != null ? formData.province : '')} name="province" required="false" register={register} errors={errors}/>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <FormField label="Telefono" value={(formData != null ? formData.phone : '')} name="phone" required="false" register={register} errors={errors}/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <FormField label="Cellulare" value={(formData != null ? formData.cell : '')} name="cell" required="false" register={register} errors={errors}/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <FormField label="PEC" name="emailPec" value={(formData != null ? formData.emailPec : '')} required="false" register={register} errors={errors}/>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <FormField label="Email" name="email" value={(formData != null ? formData.email : '')} required="false" register={register} errors={errors}/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <FormField label="P.IVA" name="vatNumber" value={(formData != null ? formData.vatNumber : '')} required="false" register={register} errors={errors}/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <FormField label="Codice fiscale" value={(formData != null ? formData.vatNumber : '')} name="fiscalCode" required="false" register={register} errors={errors}/>
                                    </div>
                                </div>
                                <div className='modal-footer'>
                                    <button
                                        type={'button'}
                                        className={'modal-button default'}
                                        onClick={() => {  setModalOpened(false) }}
                                        text='Anulla'>Anulla</button>
                                    <button
                                        type={'submit'}
                                        className={'modal-button success'}
                                        text='confirm'>Salva</button>
                                </div>
                            </form>

                            
                        </div>
                    </Modal>
                </div>
            )}
        </div>
    )
    */
    
}

/*const mapStateToProps = (state) => ({
    user: state.user,
    UI: state.UI,
})*/

export default ActiveInstancesState
