import React from "react";
import { Helmet } from "react-helmet";
import HeaderTwo from "../../../../components/header/HeaderTwo";
import CopyRightTwo from "../../../../components/footer/CopyRightTwo";
import FooterTwo from "../../../../components/footer/FooterTwo";
import Pricing from "../../../../components/pricing/pricing-one/Pricing";
import CallToActionThree from "../../../../components/call-to-action/CallToActionThree";

const PricingCustomerSupport = () => {
  return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>
          WeCo | Piani & Prezzi
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderTwo />
      {/* End Header */}

      {/* =============================================
				Pricing Section Two
			==============================================  */}
      <div className="pricing-section-two pricing-section-two-custom">
        <div className="fancy-hero-four">
          <div className="shapes shape-one"></div>
          <div className="shapes shape-two"></div>
          <div className="shapes shape-three"></div>
          <div className="shapes shape-four"></div>
          <div className="shapes shape-five"></div>
          <div className="shapes shape-six"></div>
          <div className="bg-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-xl-10 col-lg-11 m-auto">
                  <h2>Scegli il piano più adatto a te</h2>
                </div>
                <div className="col-12 m-auto">
                  <p className="font-rubik sub-heading">
                    Configurazione rapida · Approccio modulare ·
                    Piano modificabile nel tempo
                  </p>
                </div>
              </div>
              {/* End .row */}
            </div>
            {/* End .container */}
          </div>
          {/*  /.bg-wrapper */}
        </div>
        {/* /.fancy-hero-four */}

        <div className="container">
          <div className="pricing-table-area">
            <div className="tab-content">
              <Pricing />
            </div>
            <img
              src="images/shape/64.svg"
              alt="shape"
              className="shapes shape-one"
            />
            <img
              src="images/shape/65.svg"
              alt="shape"
              className="shapes shape-two"
            />
          </div>
          {/*  /.pricing-table-area  */}
        </div>
      </div>
      {/* /.pricing-section-two  */}


      {/* 	=====================================================
				FAQ Section
			===================================================== */}
      <div className="faq-section">
        <div className="container">
          {/* <div className="title-style-two text-center mb-100 md-mb-80">
            <p>FAQ</p>
            <h2>Question & Answer</h2>
          </div> */}
          {/* /.title-style-one */}

          {/* <div className="row">
            <div className="col-xl-9 col-lg-10 m-auto">
              <FaqThree />
            </div>
          </div> */}
          {/* /.row */}

          {/* <div
            className="text-center more-faq-meta mt-75 md-mt-50"
            data-aos="fade-up"
            data-aos-duration="1200"
          >
            <h3 className="mb-35 font-rubik">Non trovi la risposta?</h3>
            <Link to='#'
              onClick={(e) => {
                  window.location.href = 'mailto:weco@beatdata.it';
                  e.preventDefault();
              }} 
              className="signUp-action">
              Contattaci
            </Link>
          </div> */}
        </div>
      </div>
      {/* End .faq-section */}

      {/* 	=====================================================
			Fancy Short Banner Three
			===================================================== */}
      <div className="fancy-short-banner-three mt-200 md-mt-150">
        <div className="container">
          <div className="bg-wrapper">
            <CallToActionThree />
          </div>
          {/* /.bg-wrapper */}
        </div>
        {/* /.container */}
      </div>
      {/* /.fancy-short-banner-four */}

      {/* 	=====================================================
				Footer Style Two
			===================================================== */}
      <footer className="theme-footer-two pt-150 md-pt-80">
        <div className="top-footer">
          <div className="container">
            <FooterTwo />
          </div>
          {/* /.container */}
        </div>
        {/* /.top-footer */}

        <div className="container">
          <div className="bottom-footer-content">
            <CopyRightTwo />
          </div>
          {/*  /.bottom-footer */}
        </div>
      </footer>
      {/* /.theme-footer-one */}
    </div>
  );
};

export default PricingCustomerSupport;
