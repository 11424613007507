import React from "react";
import { Link } from "react-router-dom";

const AboutTwo = () => {
  return (
    <div className="row">
      <div
        className="col-lg-5 col-md-6 col-sm-10 m-auto"
        data-aos="fade-right"
        data-aos-duration="1200"
      >
        <div className="img-holder">
          <img src="images/media/img_19.png" alt="media" />
          <img
            src="images/shape/34.svg"
            alt="shape"
            className="shapes shape-six"
          />
        </div>
        {/* img-holder */}
      </div>
      {/* End .col */}

      <div
        className="col-xl-6 col-lg-7 ml-auto"
        data-aos="fade-left"
        data-aos-duration="1200"
      >
        <div className="quote-wrapper pt-60">
          <img src="images/icon/26.svg" alt="icon" className="icon" />
          <blockquote className="font-rubik">
          We.Co supporta l’erogazione dei tuoi corsi ed eventi FAD accreditati ECM (sincroni e asincroni) in totale conformità alle disposizioni Age.na.s.
          </blockquote>
          <Link to='#'
            onClick={(e) => {
                window.location.href = 'mailto:weco@beatdata.it';
                e.preventDefault();
            }} 
            className="theme-btn-two mt-45 md-mt-30">
            Contattaci
          </Link>
        </div>
        {/* /.quote-wrapper */}
      </div>
      {/* End .col */}
    </div>
  );
};

export default AboutTwo;
