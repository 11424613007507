import { useTable } from 'react-table';

const Table = ({ columns, data, addCallback, title, description, addBtn }) => {
   
    // Use the useTable Hook to send the columns and data to build the table
    const {
        getTableProps, // table props from react-table
        getTableBodyProps, // table body props from react-table
        headerGroups, // headerGroups, if your table has groupings
        rows, // rows for the table based on the data passed
        prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    } = useTable({ columns, data })

    return (
        <div className={'table-stripped'}>
            <h3 className={"card-title align-items-start flex-column"}>
                <span className={"card-label fw-bold text-gray-800"}>{title}</span>
                <span className={"text-gray-400 mt-1 fw-semibold fs-6"}>{description}</span>
                {addBtn && <button className='add-btn' onClick={addCallback}>Aggiungi</button>}
            </h3>
            <div className={'-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8'}>
                <div className={'py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'}>
                    <div className={' overflow-hidden border-b border-gray-200 sm:rounded-lg'}>
                        <table className={'min-w-full divide-y divide-gray-200'} {...getTableProps()}>
                            <thead className={'bg-gray-50'}>
                                {headerGroups.map((headerGroup) => (
                                    // eslint-disable-next-line react/jsx-key
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => (
                                            // eslint-disable-next-line react/jsx-key
                                            <th
                                                className={
                                                    'group  text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                                                }
                                                {...column.getHeaderProps()}
                                            >
                                                <div className={'flex items-center justify-between'}>
                                                    {column.render('Header')}
                                                </div>
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody className={'bg-white divide-y divide-gray-200'} {...getTableBodyProps()}>
                                {rows.map((row) => {
                                    prepareRow(row)
                                    return (
                                        // eslint-disable-next-line react/jsx-key
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map((cell) => {
                                                return (
                                                    // eslint-disable-next-line react/jsx-key
                                                    <td
                                                        className={' whitespace-nowrap'}
                                                        {...cell.getCellProps()}
                                                    >
                                                        {cell.render('Cell')}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Table
