import React from "react";

const PricingContent = [
  {
    packName: "Starter Pack",
    price: "99",
    billCycle: "Per mese",
    preFeatures: [
      "Corsi in aula categorizzabili",
      "Calendario integrabile in Office 365",
      "Gestione Docenti, Clienti, Aule e Sedi",
      "Esportazione Age.na.s",
      "Reportistica corsi avanzata",
      "Gestione Sconti e Rateizzazioni",
      "Fatture Elettroniche",
      "Stato Incassi",
      "Metodi di pagamento e Voucher",
      "Messaggi Email",
    ],
  },
  {
    packName: "Streaming Module",
    price: "199",
    billCycle: "Per mese",
    preFeatures: [
      "Gestione Corsi VOD ",
      "Eventi LIVE",
      "Meeting ",
      "Gestione Allegati ",
      "Survey Avanzati",
      "Statistiche di utilizzo",
      "Integrazione API",
    ],
  },
];

const PricingYearly = () => {
  return (
    <div className="row no-gutters">
      {PricingContent.map((val, i) => (
        <div className="col-lg-6 col-sm-6 pr-bg d-flex" key={i}>
          <div className="pr-table-wrapper">
            <div className="pack-name">{val.packName}</div>
            <div className="price">
              <sup>€</sup>
              {val.price}
            </div>
            <div className="bill-cycle">{val.billCycle}</div>
            <a href="mailto:weco@beatdata.it" className="theme-btn-three">
            Richiedi informazioni
            </a>
            <ul className="pr-feature">
              {val.preFeatures.map((list, i) => (
                <li key={i}>{list}</li>
              ))}
            </ul>
          </div>
          {/* /.pr-table-wrapper  */}
        </div>
      ))}
    </div>
  );
};

export default PricingYearly;
