import React from "react";
import { Link } from "react-router-dom";

const socialContent = [
  {
    icon: "fa-linkedin",
    link: "https://www.linkedin.com/company/beatdata",
  },
  {
    icon: "fa-twitter",
    link: "https://mobile.twitter.com/beatdatasrl",
  },
];

const FooterTwo = () => {
  return (
    <div className="row justify-content-between">
      <div
        className="col-lg-2 col-12 footer-about-widget"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <a href="index-customer-support.html" className="logo">
          <img src="images/logo/logo.png" alt="brand" className="logo-weco"/>
        </a>
      </div>
      {/* /.about-widget */}
      <div
        className="col-lg-2 col-md-3 col-sm-6 footer-list"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="50"
      >

      </div>
      {/* /.footer-list */}
      <div
        className="col-lg-2 col-md-3 col-sm-6 footer-list"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="100"
      >

      </div>
      {/* /.footer-list */}
      <div
        className="col-lg-2 col-md-3 col-sm-6 footer-list"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="150"
      >
        <h5 className="footer-title">About us</h5>
        <ul>
          <li>
            <Link to='#'
                onClick={(e) => {
                    window.location.href = 'https://beatdata.it';
                    e.preventDefault();
                }}>Azienda</Link>
          </li>
          <li>
            <Link to="#">Faq</Link>
          </li>
          <li>
            <Link to="/pricing-cs">Piani & Prezzi</Link>
          </li>
          <li>
            <Link to="/termini-e-condizioni">Termini e condizioni</Link>
          </li>
        </ul>
      </div>
      {/* /.footer-list */}
      <div
        className="col-lg-3 col-md-3 col-sm-6 address-list"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="200"
      >
        <h5 className="footer-title">Address</h5>
        <ul className="info">
        <li>
            <a href="mailto:weco@beatdata.it">weco@beatdata.it</a>
          </li>
          <li>
            <a href="Tel: 02 91943951" className="mobile-num">
              +39 02 91943951
            </a>
          </li>
        </ul>
        <ul className="social-icon d-flex">
          {socialContent.map((val, i) => (
            <li key={i}>
              <a href={val.link} target="_blank" rel="noreferrer">
                <i className={`fa ${val.icon}`}></i>
              </a>
            </li>
          ))}
        </ul>
      </div>
      {/* /.footer-list */}
    </div>
    //.row
  );
};

export default FooterTwo;
