import React from "react";
//import { Helmet } from "react-helmet";
import HeaderTwo from "../../../../components/header/HeaderTwo";
import CopyRightTwo from "../../../../components/footer/CopyRightTwo";
import FooterTwo from "../../../../components/footer/FooterTwo";
//import Pricing from "../../../../components/pricing/pricing-one/Pricing";
//import CallToActionThree from "../../../../components/call-to-action/CallToActionThree";
//import DashboardView from "../../../../../src/components/dashboard/Dashboard"
import { Link } from "react-router-dom";
import DocumentsView from "../../../../components/documents/Documents";

const Dashboard = () => {
    var user = JSON.parse(localStorage.getItem('user'));
    return (
        <div className="main-page-wrapper p0 custom-dashboard-view">
            <HeaderTwo />
            <div className="pricing-section-two pricing-section-two-custom">
                <div className="fancy-hero-four">
                    <div className="shapes shape-one"></div>
                    <div className="shapes shape-two"></div>
                    <div className="shapes shape-three"></div>
                    <div className="shapes shape-four"></div>
                    <div className="shapes shape-five"></div>
                    <div className="shapes shape-six"></div>
                    <div className="bg-wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-10 col-lg-11 m-auto">
                                </div>
                            </div>
                            {/* End .row */}
                        </div>
                        {/* End .container */}
                    </div>
                    {/*  /.bg-wrapper */}
                </div>
                {/* /.fancy-hero-four */}

                <div className="container" style={{ minWidth: '90%' }}>
                    <div>
                        <div className="row">
                            <div className="col-md-3">

                                <div className="private-menu-area">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <img src="https://i.pinimg.com/originals/ff/a0/9a/ffa09aec412db3f54deadf1b3781de2a.png" alt="" style={{ width: '150px' }} />
                                        </div>
                                        <div className="col-md-8">
                                            <h4>Benvenuto</h4>
                                            <h3>{ user.username }</h3>
                                        </div>
                                    </div>
                                    <ul>
                                        <li><Link to="dashboard" className="signIn-action">Dashboard</Link></li>
                                        <li><Link to="documents" className="signIn-action">Documenti</Link></li>
                                        <li>Logout</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="pricing-table-area">
                                    <div className="tab-content">
                                        <DocumentsView />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <img
                            src="images/shape/64.svg"
                            alt="shape"
                            className="shapes shape-one"
                        />
                        <img
                            src="images/shape/65.svg"
                            alt="shape"
                            className="shapes shape-two"
                        />
                    </div>
                    {/*  /.pricing-table-area  */}
                </div>
            </div>
            {/* /.pricing-section-two  */}


            {/* 	=====================================================
                    FAQ Section
                ===================================================== */}
            <div className="faq-section">
                <div className="container">
                    {/* <div className="title-style-two text-center mb-100 md-mb-80">
                <p>FAQ</p>
                <h2>Question & Answer</h2>
              </div> */}
                    {/* /.title-style-one */}

                    {/* <div className="row">
                <div className="col-xl-9 col-lg-10 m-auto">
                  <FaqThree />
                </div>
              </div> */}
                    {/* /.row */}

                    {/* <div
                className="text-center more-faq-meta mt-75 md-mt-50"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <h3 className="mb-35 font-rubik">Non trovi la risposta?</h3>
                <Link to='#'
                  onClick={(e) => {
                      window.location.href = 'mailto:weco@beatdata.it';
                      e.preventDefault();
                  }} 
                  className="signUp-action">
                  Contattaci
                </Link>
              </div> */}
                </div>
            </div>
            {/* End .faq-section */}

            {/* 	=====================================================
                Fancy Short Banner Three
                ===================================================== */}

            {/* /.fancy-short-banner-four */}

            {/* 	=====================================================
                    Footer Style Two
                ===================================================== */}
            <footer className="theme-footer-two pt-150 md-pt-80">
                <div className="top-footer">
                    <div className="container">
                        <FooterTwo />
                    </div>
                    {/* /.container */}
                </div>
                {/* /.top-footer */}

                <div className="container">
                    <div className="bottom-footer-content">
                        <CopyRightTwo />
                    </div>
                    {/*  /.bottom-footer */}
                </div>
            </footer>
            {/* /.theme-footer-one */}
        </div>
    );
};

export default Dashboard;
