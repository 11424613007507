import axios from 'axios'
import ServicesConfig from './ServicesConfig';

class LeadServices {
    list() {
        return axios.get( ServicesConfig.baseUrl + 'leads/list')
            .then((response) => {
                return response.data
        });
    }

    create(data) {
        return axios.post( ServicesConfig.baseUrl + 'leads/create', data)
            .then((response) => {
                return response.data
        });
    }

    get(id) {
        return axios.get( ServicesConfig.baseUrl + 'leads/get/' + id)
            .then((response) => {
                return response.data
        });
    }

    save(data) {
        return axios.post( ServicesConfig.baseUrl + 'leads/edit', data)
            .then((response) => {
                return response.data
        });
    }
    convert(data) {
        return axios.post( ServicesConfig.baseUrl + 'leads/convert', data)
            .then((response) => {
                return response.data
        });
    }

}

export default new LeadServices()
