import React from "react";
import { Link } from "react-router-dom";

const MegaMenu = () => {
  return (
    <ul className="navbar-nav">
      <li className="nav-item dropdown position-static">
        <Link className="nav-link" to="/">Home</Link>
      </li>
      {/* End li */}

      <li className="nav-item dropdown">
        <a className="nav-link" href="/#caratteristiche" data-toggle="dropdown">
          Caratteristiche
        </a>
      </li>
      {/* End li */}

      <li className="nav-item dropdown">
        <Link className="nav-link" to="/pricing-cs">Piani & Prezzi</Link>
      </li>
      {/* End li */}

      {/* <li className="nav-item dropdown">
        <Link className="nav-link" to="/team">Team</Link>
      </li> */}
      {/* End li */}

      <li className="nav-item dropdown">
        <a className="nav-link" href="https://beatdata.it" data-toggle="dropdown">
          Azienda
        </a>
      </li>
      {/* End li */}
    </ul>
  );
  // End navbar nav mega menu main
};

export default MegaMenu;
